import * as React from 'react';
import { PageProps, graphql } from 'gatsby';
import Seo from '@components/Seo';
import { PrismicAcademySitemap } from '@utils/typings/prismic-types';

interface Props extends PageProps {
  data: {
    prismicAcademySitemap: PrismicAcademySitemap;
  };
}

const isBrowser = typeof window !== 'undefined';

export default function AcademyPage({ data, location }: Props) {
  const firstDocUrl =
    data.prismicAcademySitemap.data.top_level_pages[0].doc_page.url ||
    data.prismicAcademySitemap.data.body[0].items[0].doc_page.url;

  if (isBrowser) {
    // We have to use window.locaiton becuase navigating with reach router
    // doesn't actually switch pages for some reason.
    window.location.href = `${location.origin}${firstDocUrl}`;
  }
  return <Seo title="Pry Academy" />;
}

export const query = graphql`
  query academyIndexQuery($lang: String) {
    prismicAcademySitemap(lang: { eq: $lang }) {
      data {
        top_level_pages {
          doc_page {
            url
          }
        }
        body {
          ... on PrismicAcademySitemapDataBodyAcademySection {
            id
            items {
              doc_page {
                url
              }
            }
          }
        }
      }
    }
  }
`;
